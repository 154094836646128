import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import List from "../../components/list"
import Hero from "../../components/hero"
import MailTo from "../../components/mail-to"

const content1 = {
  title: 'Product screenshots',
  backgroundImage: require('../../images/press-banner1.png'),
  description: "Our carefully curated selection of product screenshots help to show Billsby off - they're available in the highest resolutions we could find, and we've even put together some mock-ups for you with devices and backgrounds to help make things as easy as we can.",
  url: 'https://airfinetwork-my.sharepoint.com/:f:/g/personal/mark_howson_airfinetworks_com/EjZ4wYuKQb1Ir6jaXpdyBM0BoJZUZLCYx2Fc-piKn5GNtw?e=EckgJo',
  buttonColor: 'orange',
  buttonText: 'View all'
}

const content2 = {
  title: "Leadership team",
  backgroundImage: require('../../images/press-banner2.png'),
  description: "Our leadership team are an inspiring bunch. If they're speaking at your event, being interviewed in your magazine or you're just looking to setup some kind of slightly concerning shrine, this is the section of the website we made especially for you. You're welcome.",
  url: 'https://airfinetwork-my.sharepoint.com/:f:/g/personal/mark_howson_airfinetworks_com/ErpxIh-XjTdCnL2qetKPN2YB9XLJFymRFn_C-HQhU--1Mw?e=Nn247d',
  buttonColor: 'orange',
  buttonText: 'View all'
}

const content3 = {
  title: "Logos",
  backgroundImage: require('../../images/press-banner3.png'),
  description: "You'll find all of our logos here - in every colour and format you might need. We've included logos for both Billsby and Billsby Pro. We haven't included any pictures of Billsbot though - he's not a logo, he's a mascot. And he likes to live right here on our website.",
  url: 'https://airfinetwork-my.sharepoint.com/:f:/g/personal/mark_howson_airfinetworks_com/EoB0Qhc2-d1Kr19RKk7v-b0BAPD3PyLPu9xah-fBBdekhQ?e=7TS2Bt',
  buttonColor: 'orange',
  buttonText: 'View all'
}

const content4 = {
  title: "Brand guidelines and rules",
  backgroundImage: require('../../images/press-banner4.png'),
  description: "Whichever of our assets you've accessed, it's really important you understand and follow the rules - because if you don't you'll need to ask permission first. These guidelines help you to understand how you can use our brand in a way that we can all agree on.",
  url: 'https://airfinetwork-my.sharepoint.com/:f:/g/personal/mark_howson_airfinetworks_com/EkCvFeh3jY9GgPucA4p0oDEBbJQY8Or9KPyg8dHnMYgA0Q?e=DpgKUb',
  buttonColor: 'orange',
  buttonText: 'View all'
}

const bannerContent = {
  title: "Press kit",
  content: <>We've created some guidelines and resources to help you use our brand and assets, including our logo, content and trademarks, without having to ask our permission for each use. To make use of them in a way that isn't covered here, please contact us via {MailTo({classes: ''})} to discuss.</>
}

const Press = () => {
  return (
    <Layout>
      <SEO 
        title="Press" 
        description="Our press kit includes all of the information and resources you'll need to share Billsby with your readers, ready for you to use and download." 
        url="https://www.billsby.com/company/press"
      />
      <div className="press">
        <Hero contentHero={bannerContent} />

        <div className="section-press">
           <List content={content1} />
           <List content={content2} />
           <List content={content3} />
           <List content={content4} />
        </div>
      </div>
    </Layout>
  )
}

export default Press
